@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:300,400,500,600,700,800,900|Barlow:300,400,500,600,700,800,900&display=swap");

.not-found-page {
  background-color: #695681;
}

.not-found-page h1,
.not-found-page h2,
.not-found-page h3,
.not-found-page h4,
.not-found-page h5,
.not-found-page h6,
.not-found-page p,
.not-found-page ul,
.not-found-page li,
.not-found-page button,
.not-found-page a,
.not-found-page i,
.not-found-page input,
.not-found-page body {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 0;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  color: inherit;
}
.not-found-page h1:focus,
.not-found-page h2:focus,
.not-found-page h3:focus,
.not-found-page h4:focus,
.not-found-page h5:focus,
.not-found-page h6:focus,
.not-found-page p:focus,
.not-found-page ul:focus,
.not-found-page li:focus,
.not-found-page button:focus,
.not-found-page a:focus,
.not-found-page i:focus,
.not-found-page input:focus,
.not-found-page body:focus {
  outline: 0;
}
.not-found-page body {
  margin: 0;
  padding: 0;
  height: auto;
  font-family: "Barlow", sans-serif;
  background: #695681;
}
.not-found-page .logo {
  position: fixed;
  z-index: 5;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}
.not-found-page .logo img {
  width: 55%;
  height: 55%;
  transform: translateY(-1px);
  opacity: 0.8;
}
.not-found-page nav .menu {
  width: 100%;
  height: 80px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  box-sizing: border-box;
  z-index: 3;
}
.not-found-page nav .menu .website_name {
  color: #695681;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 1px;
  background: white;
  padding: 4px 8px;
  border-radius: 2px;
  opacity: 0.5;
  transition: all 0.4s ease;
  cursor: pointer;
}
.not-found-page nav .menu .website_name:hover {
  opacity: 1;
}
.not-found-page nav .menu .menu_links {
  transition: all 0.4s ease;
  opacity: 0.5;
}
.not-found-page nav .menu .menu_links:hover {
  opacity: 1;
}
@media screen and (max-width: 799px) {
  .not-found-page nav .menu .menu_links {
    display: none;
  }
}
.not-found-page nav .menu .menu_links .link {
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 50px;
  letter-spacing: 2px;
  position: relative;
  transition: all 0.3s 0.2s ease;
}
.not-found-page nav .menu .menu_links .link:last-child {
  margin-right: 0;
}
.not-found-page nav .menu .menu_links .link:before {
  content: "";
  position: absolute;
  width: 0px;
  height: 4px;
  background: linear-gradient(90deg, #ffedc0 0%, #ff9d87 100%);
  bottom: -10px;
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.82, 0.02, 0.13, 1.26);
  left: 100%;
}
.not-found-page nav .menu .menu_links .link:hover {
  opacity: 1;
  color: #fb8a8a;
}
.not-found-page nav .menu .menu_links .link:hover:before {
  width: 40px;
  left: 0;
}
.not-found-page nav .menu .menu_icon {
  width: 40px;
  height: 40px;
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 799px) {
  .not-found-page nav .menu .menu_icon {
    display: flex;
  }
}
.not-found-page nav .menu .menu_icon .icon {
  width: 24px;
  height: 2px;
  background: white;
  position: absolute;
}
.not-found-page nav .menu .menu_icon .icon:before,
.not-found-page nav .menu .menu_icon .icon:after {
  content: "";
  width: 100%;
  height: 100%;
  background: inherit;
  position: absolute;
  transition: all 0.3s cubic-bezier(0.49, 0.04, 0, 1.55);
}
.not-found-page nav .menu .menu_icon .icon:before {
  transform: translateY(-8px);
}
.not-found-page nav .menu .menu_icon .icon:after {
  transform: translateY(8px);
}
.not-found-page nav .menu .menu_icon:hover .icon {
  background: #ffedc0;
}
.not-found-page nav .menu .menu_icon:hover .icon:before {
  transform: translateY(-10px);
}
.not-found-page nav .menu .menu_icon:hover .icon:after {
  transform: translateY(10px);
}
.not-found-page .wrapper {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-x: hidden;
}
.not-found-page .wrapper .container {
  margin: 0 auto;
  transition: all 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.not-found-page .wrapper .container .scene {
  position: absolute;
  width: 100vw;
  /* height: 100vh; */
  vertical-align: middle;
}
.not-found-page .wrapper .container .one,
.not-found-page .wrapper .container .two,
.not-found-page .wrapper .container .three,
.not-found-page .wrapper .container .circle,
.not-found-page .wrapper .container .p404 {
  width: 60%;
  height: 60%;
  top: 20% !important;
  left: 20% !important;
  min-width: 400px;
  min-height: 400px;
}
.not-found-page .wrapper .container .one .content,
.not-found-page .wrapper .container .two .content,
.not-found-page .wrapper .container .three .content,
.not-found-page .wrapper .container .circle .content,
.not-found-page .wrapper .container .p404 .content {
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: content 0.8s cubic-bezier(1, 0.06, 0.25, 1) backwards;
}
@keyframes content {
  0% {
    width: 0;
  }
}
.not-found-page .wrapper .container .one .content .piece,
.not-found-page .wrapper .container .two .content .piece,
.not-found-page .wrapper .container .three .content .piece,
.not-found-page .wrapper .container .circle .content .piece,
.not-found-page .wrapper .container .p404 .content .piece {
  width: 150px;
  height: 80px;
  display: flex;
  position: absolute;
  border-radius: 80px;
  z-index: 1;
  animation: pieceLeft 8s cubic-bezier(1, 0.06, 0.25, 1) infinite both;
}
@keyframes pieceLeft {
  50% {
    left: 80%;
    width: 10%;
  }
}
@keyframes pieceRight {
  50% {
    right: 80%;
    width: 10%;
  }
}
@media screen and (max-width: 799px) {
  .not-found-page .wrapper .container .one,
  .not-found-page .wrapper .container .two,
  .not-found-page .wrapper .container .three,
  .not-found-page .wrapper .container .circle,
  .not-found-page .wrapper .container .p404 {
    width: 90%;
    height: 90%;
    top: 5% !important;
    left: 5% !important;
    min-width: 280px;
    min-height: 280px;
  }
}
@media screen and (max-height: 660px) {
  .not-found-page .wrapper .container .one,
  .not-found-page .wrapper .container .two,
  .not-found-page .wrapper .container .three,
  .not-found-page .wrapper .container .circle,
  .not-found-page .wrapper .container .p404 {
    min-width: 280px;
    min-height: 280px;
    width: 60%;
    height: 60%;
    top: 20% !important;
    left: 20% !important;
  }
}
.not-found-page .wrapper .container .text {
  width: 60%;
  height: 40%;
  min-width: 400px;
  min-height: 350px;
  position: absolute;
  margin: 40px 0;
  animation: text 0.6s 1.8s ease backwards;
}
@keyframes text {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
}
/* @media screen and (max-width: 799px) {
  .not-found-page .wrapper .container .text {
    min-height: 400px;
    height: 80%;
  }
} */
.not-found-page .wrapper .container .text article {
  width: 400px;
  position: absolute;
  bottom: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 799px) {
  .not-found-page .wrapper .container .text article {
    width: 60%;
  }
}
.not-found-page .wrapper .container .text article p {
  color: white;
  font-size: 14px;
  letter-spacing: 0.6px;
  margin-bottom: 12px;
  text-shadow: 6px 6px 10px #32243e;
}
.not-found-page .wrapper .container .text article button {
  height: 40px;
  padding: 0 30px;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 15px 20px rgba(54, 24, 79, 0.5);
  z-index: 3;
  color: #695681;
  background-color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  transition: all 0.3s ease;
}
.not-found-page .wrapper .container .text article button:hover {
  box-shadow: 0px 10px 10px -10px rgba(54, 24, 79, 0.5);
  transform: translateY(5px);
  background: #fb8a8a;
  color: white;
}
.not-found-page .wrapper .container .p404 {
  font-size: 150px;
  font-weight: 700;
  letter-spacing: 4px;
  color: white;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  animation: anime404 0.6s cubic-bezier(0.3, 0.8, 1, 1.05) both;
  animation-delay: 1.2s;
}
@media screen and (max-width: 799px) {
  .not-found-page .wrapper .container .p404 {
    font-size: 100px;
  }
}
@keyframes anime404 {
  0% {
    opacity: 0;
    transform: scale(10) skew(20deg, 20deg);
  }
}
.not-found-page .wrapper .container .p404:nth-of-type(2) {
  color: #36184f;
  z-index: 1;
  animation-delay: 1s;
  filter: blur(10px);
  opacity: 0.8;
}
.not-found-page .wrapper .container .circle {
  position: absolute;
}
.not-found-page .wrapper .container .circle:before {
  content: "";
  position: absolute;
  width: 400px;
  height: 400px;
  background-color: rgba(54, 24, 79, 0.2);
  border-radius: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: inset 5px 20px 40px rgba(54, 24, 79, 0.25),
    inset 5px 0px 5px rgba(50, 36, 62, 0.3),
    inset 5px 5px 20px rgba(50, 36, 62, 0.25),
    2px 2px 5px rgba(255, 255, 255, 0.2);
  animation: circle 0.8s cubic-bezier(1, 0.06, 0.25, 1) backwards;
}
@keyframes circle {
  0% {
    width: 0;
    height: 0;
  }
}
@media screen and (max-width: 799px) {
  .not-found-page .wrapper .container .circle:before {
    max-width: 400px;
    max-height: 400px;
  }
}
@media screen and (max-width: 500px) {
  .not-found-page .wrapper .container .circle {
    position: unset;
  }
  .not-found-page .wrapper .container .circle:before {
    max-width: 300px;
    max-height: 300px;
  }
}
.not-found-page .wrapper .container .one .content:before {
  content: "";
  position: absolute;
  width: 500px;
  height: 500px;
  background-color: rgba(54, 24, 79, 0.3);
  border-radius: 100%;
  box-shadow: inset 5px 20px 40px rgba(54, 24, 79, 0.25),
    inset 5px 0px 5px rgba(50, 36, 62, 0.3),
    inset 5px 5px 20px rgba(50, 36, 62, 0.25),
    2px 2px 5px rgba(255, 255, 255, 0.2);
  animation: circle 0.8s 0.4s cubic-bezier(1, 0.06, 0.25, 1) backwards;
}
@media screen and (max-width: 799px) {
  .not-found-page .wrapper .container .one .content:before {
    width: 300px;
    height: 300px;
  }
}
@media screen and (max-width: 500px) {
  .not-found-page .wrapper .container .one .content:before {
    position: absolute;
    max-width: 250px;
    max-height: 250px;
  }
}
.not-found-page .wrapper .container .one .content .piece {
  background: linear-gradient(90deg, #8077ea 13.7%, #eb73ff 94.65%);
}
.not-found-page .wrapper .container .one .content .piece:nth-child(1) {
  right: 15%;
  top: 18%;
  height: 30px;
  width: 120px;
  animation-delay: 0.5s;
  animation-name: pieceRight;
}
.not-found-page .wrapper .container .one .content .piece:nth-child(2) {
  left: 15%;
  top: 45%;
  width: 150px;
  height: 50px;
  animation-delay: 1s;
  animation-name: pieceLeft;
}
.not-found-page .wrapper .container .one .content .piece:nth-child(3) {
  left: 10%;
  top: 75%;
  height: 20px;
  width: 70px;
  animation-delay: 1.5s;
  animation-name: pieceLeft;
}
.not-found-page .wrapper .container .two .content .piece {
  background: linear-gradient(90deg, #ffedc0 0%, #ff9d87 100%);
}
.not-found-page .wrapper .container .two .content .piece:nth-child(1) {
  left: 0%;
  top: 25%;
  height: 40px;
  width: 120px;
  animation-delay: 2s;
  animation-name: pieceLeft;
}
.not-found-page .wrapper .container .two .content .piece:nth-child(2) {
  right: 15%;
  top: 35%;
  width: 180px;
  height: 50px;
  animation-delay: 2.5s;
  animation-name: pieceRight;
}
.not-found-page .wrapper .container .two .content .piece:nth-child(3) {
  right: 10%;
  top: 80%;
  height: 20px;
  width: 160px;
  animation-delay: 3s;
  animation-name: pieceRight;
}
.not-found-page .wrapper .container .three .content .piece {
  background: #fb8a8a;
}
.not-found-page .wrapper .container .three .content .piece:nth-child(1) {
  left: 25%;
  top: 35%;
  height: 12px;
  width: 50px;
  animation-name: pieceLeft;
  animation-delay: 3.5s;
}
.not-found-page .wrapper .container .three .content .piece:nth-child(2) {
  right: 10%;
  top: 55%;
  width: 80px;
  height: 20px;
  animation-name: pieceRight;
  animation-delay: 4s;
}
.not-found-page .wrapper .container .three .content .piece:nth-child(3) {
  left: 40%;
  top: 68%;
  height: 10px;
  width: 70px;
  animation-name: pieceLeft;
  animation-delay: 4.5s;
}

@media screen and (max-width: 460px) {
  
}