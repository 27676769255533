.flex {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* flex-wrap: wrap; */
}

.section__title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.dashboardSection {
  width: 100%;
  background: #08071b;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  /* margin-top: 50px; */
  flex-wrap: wrap;
}

.dashboardSection:hover {
  box-shadow: 0px 0px 10px rgba(64, 62, 128, 0.5);
}

.dashboardSection p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  margin: 0;
  /* margin-top: 10px; */
}

.dashboardSection button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  padding: 4px 8px;
  color: #ffffff;
  background: #1890ff;
  border-radius: 4px;
  border: none;
  margin-left: 14px;
}

.dashboardSection h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #f7f9fc;
  margin: 0;
  margin-left: 5px;
}

.platform__item {
  width: 30%;
  height: 180px;
  background: rgba(255, 255, 255, 0.24);
  border: 1px solid #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.platform__item:hover {
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
}

.platform__item img {
  height: 50px;
  width: auto;
}

.platform__item h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin: 0;
  margin-top: 10px;
}

.wallet__item {
  min-width: 254px;
  height: 118px;
  background: #08071b;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.wallet__item:hover {
  box-shadow: 0px 0px 10px rgba(64, 62, 128, 0.5);
}

.wallet__item__imgSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  background: rgba(63, 148, 247, 0.2);
  border-radius: 8px;
}

.wallet__item__imgSection img {
  color: rgba(63, 148, 247, 1);
}

.wallet__item h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  /* line-height: 48px; */
  color: #ffffff;
  margin: 0;
  /* margin-top: 10px; */
}

.wallet__item p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 21px; */
  color: #ffffff;
  margin: 0;
  /* margin-top: 10px; */
}

.pro__item {
  width: 49%;
  background: #08071b;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding: 20px;
}

.pro__item:hover {
  box-shadow: 0px 0px 10px rgba(64, 62, 128, 0.5);
}

.pro__item h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin: 0;
}

.pro__item p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.75);
  margin: 10px 0;
}

.pro__item button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 8px 16px;
  color: #ffffff;
  background: #1890ff;
  border-radius: 6px;
  border: none;
}

@media screen and (max-width: 1370px) {
  /* .walletItemSection,
  .platformItemSection {
    overflow-x: scroll;
  } */

  .walletItemSection .wallet__item,
  .platformItemSection .platform__item {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 950px) {
  .platformItemSection .platform__item {
    padding-left: 10px;
    padding-right: 15px;
  }
  .wallet__item {
    height: unset;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 950px) {
  .walletItemSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .wallet__item {
    height: 118px;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}
/* @media screen and (m-width: 1000px) {
  .wallet__item {
    width: 254px;
    height: unset;
    background: #08071b;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
} */
