/* login */

.authSection {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

.w_50 {
  width: 50%;
}

.authSection img {
  width: 100%;
  height: 100%;
}

.auth_leftSection {
  /* background-color: #f5f5f5; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-left: 97px;
  padding-right: 47px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* object-fit: contain; */
}

.auth_leftSection h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #ffffff;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
}

.auth_leftSection h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
}

.auth_leftSection ul li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  color: #ffffff;
  /* filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25)); */
}

.auth_rightSection {
  /* background-color: #1e1e1e; */
  background-color: #08071b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 122px;
}

.auth_rightSection .logo {
  width: 188px;
  height: auto;
}

.auth_rightSection .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  color: #3f94f7;
  margin-bottom: 0;
}

.auth_rightSection .desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 50px;
}

.auth_rightSection .form {
  width: 498px;
}

.forgot_password_section {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 35px;
}

.forgot_password_section .forgot_password_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #ffffff;
  text-decoration: underline;
}

.auth_rightSection .errorText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: tomato;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 35px;
}

.auth_rightSection .redirect_link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}

@media (width <=1440px) {
  .auth_leftSection {
    padding-left: 80px;
    padding-right: 40px;
  }
  .auth_leftSection h1 {
    font-size: 32px;
    line-height: 48px;
  }
  .auth_leftSection h2 {
    font-size: 24px;
    line-height: 36px;
  }
  .auth_leftSection ul li {
    font-size: 16px;
    line-height: 32px;
  }
  .auth_rightSection {
    padding-left: 80px;
  }
  .auth_rightSection .logo {
    width: 150px;
  }
  .auth_rightSection .title {
    font-size: 28px;
  }
  .auth_rightSection .desc {
    font-size: 14px;
  }
  .auth_rightSection .form {
    width: 400px;
  }
}

@media (width <=1024px) {
  .auth_leftSection {
    padding-left: 60px;
    padding-right: 30px;
  }
  .auth_leftSection h1 {
    font-size: 24px;
    line-height: 36px;
  }
  .auth_leftSection h2 {
    font-size: 20px;
    line-height: 28px;
  }
  .auth_rightSection {
    padding-left: 60px;
  }
  .auth_rightSection .logo {
    width: 130px;
  }
  .auth_rightSection .title {
    font-size: 24px;
  }
  .auth_rightSection .desc {
    font-size: 14px;
    margin-bottom: 30px;
  }
  .auth_rightSection .form {
    width: 380px;
  }
}

@media (width <=768px) {
  .auth_leftSection {
    padding-left: 30px;
    padding-right: 20px;
  }
  .auth_leftSection h1 {
    font-size: 20px;
    line-height: 30px;
  }
  .auth_leftSection h2 {
    font-size: 16px;
    line-height: 24px;
  }
  .auth_leftSection ul li {
    font-size: 12px;
    line-height: 24px;
  }
  .auth_rightSection {
    padding-left: 30px;
  }
  .auth_rightSection .title {
    font-size: 20px;
  }
  .auth_rightSection .desc {
    font-size: 12px;
    margin-bottom: 20px;
  }
  .auth_rightSection .form {
    width: 300px;
  }
  .auth_rightSection .errorText {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .auth_rightSection .redirect_link {
    font-size: 12px;
  }
}

@media (width <=685px) {
  .auth_leftSection {
    padding-left: 20px;
    padding-right: 15px;
  }
  .auth_leftSection h1 {
    font-size: 18px;
    line-height: 30px;
  }
  .auth_rightSection {
    padding-left: 20px;
  }
  .auth_rightSection .form {
    width: 280px;
  }
}

@media (width <=615px) {
  .login_leftSection {
    display: none;
  }
  .loginSection {
    flex-direction: column;
    align-items: center;
  }
  .login_rightSection {
    min-height: 100vh;
    width: 100%;
    /* padding: 0; */
    flex-direction: column;
    align-items: center;
  }
  .auth_leftSection {
    padding-left: 15px;
    padding-right: 15px;
  }
  .auth_leftSection h1 {
    font-size: 18px;
    line-height: 30px;
  }
  .auth_rightSection .title {
    font-size: 18px;
  }
  .auth_rightSection .form {
    width: 250px;
  }
}

@media (width <=550px) {
  .auth_leftSection {
    padding-left: 15px;
    padding-right: 15px;
  }
  .auth_leftSection h1 {
    font-size: 16px;
    line-height: 24px;
  }
  .auth_leftSection h2 {
    font-size: 14px;
    line-height: 20px;
  }
  .auth_leftSection ul li {
    font-size: 12px;
    line-height: 20px;
  }
  .auth_rightSection {
    padding-left: 15px;
    padding-right: 15px;
  }
  .auth_rightSection .title {
    font-size: 16px;
  }
  .auth_rightSection .form {
    width: 200px;
  }
}

@media (width <=470px) {
  .authSection {
    flex-direction: column;
    height: 100%;
  }
  .w_50 {
    width: 100%;
  }
  .auth_leftSection h1 {
    font-size: 20px;
    line-height: 30px;
  }
  .auth_leftSection h2 {
    font-size: 16px;
    line-height: 24px;
  }
  .auth_leftSection ul li {
    font-size: 12px;
    line-height: 24px;
  }
  .auth_leftSection,
  .auth_rightSection {
    padding: 30px 40px;
  }
  .auth_rightSection .title {
    font-size: 20px;
  }
  .auth_rightSection .desc {
    font-size: 12px;
    margin-bottom: 20px;
  }
  .auth_rightSection .form {
    width: 100%;
  }
}

@media (width <=425px) {
  .auth_leftSection,
  .auth_rightSection {
    padding: 30px 25px;
  }
}
/* @media (300px < width <=500px) {} */
