.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.searchInputSection {
  width: 500px;
  height: 50px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  border: 1px solid rgba(149, 148, 156, 0.25);
  border-radius: 8px;
  padding: 0 20px;
}

.searchInputSection img {
  width: 20px;
  height: 20px;
}

.searchInputSection input {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  margin-left: 5px;
}

.searchInputSection input::placeholder,/* Firefox */
.searchInputSection input:-ms-input-placeholder,/* Internet Explorer 10-11 */
.searchInputSection input::-ms-input-placeholder  /* Microsoft Edge */ {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #95949c;
}

.profile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn {
  width: 100%;
  height: 30px;
  background: #fff;
  border: 1px solid rgba(149, 148, 156, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 5px 0;
}
