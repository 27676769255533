* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mainModal,
.mainTable {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.mainModal::-webkit-scrollbar,
.mainTable::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.main_button {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background: #3f94f7;
  border-radius: 4px;
  padding: 6px 12px;
  /* width: 498px; */
  /* width: 100%;
  height: 60px; */
  border: none;
}

.main_button:hover {
  background-color: #1976d2;
}

.copyright__container {
  width: 100%;
  background: #08071b;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin-top: 50px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (width <=1024px) {
  .main_button {
    height: 50px;
    font-size: 14px;
  }
}

@media (width <=800px) {
  .copyright__container > div {
    flex-direction: column;
  }
}

@media (width <=768px) {
  .main_button {
    height: 40px;
    font-size: 12px;
  }
  .copyright__container > p {
    padding: 0 20px;
    text-align: center;
  }
}

@media (width <=550px) {
  .main_button {
    height: 35px;
  }
  main.MuiBox-root.css-omcc94 {
    overflow-x: auto;
  }
}

@media (width <=470px) {
  .main_button {
    height: 40px;
  }
  main.MuiBox-root.css-omcc94 {
    overflow-x: auto;
  }
}
