/* MainInput */

.main_input_section {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 8px;
}

.required {
  font-family: "Roboto";
  color: #fa404b;
}

.main_input {
  padding: 18px 12px;
  /* width: 498px; */
  width: 100%;
  height: 60px;
  color: #fff;
  background: #08071b;
  border: 1px solid #828282;
  border-radius: 6px;
  font-size: 16px;
}

.main_input::placeholder,/* Firefox */
.main_input:-ms-input-placeholder,/* Internet Explorer 10-11 */
.main_input::-ms-input-placeholder  /* Microsoft Edge */ {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #83838d;
  opacity: 0.5;
}

@media (width <=1024px) {
  .main_input {
    padding: 16px 10px;
    height: 50px;
  }
}

@media (width <=768px) {
  .main_input_section {
    margin-top: 18px;
  }
  .title {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .main_input {
    padding: 14px 10px;
    height: 40px;
    font-size: 12px;
  }
  .main_input::placeholder,/* Firefox */
  .main_input:-ms-input-placeholder,/* Internet Explorer 10-11 */
  .main_input::-ms-input-placeholder  /* Microsoft Edge */ {
    font-size: 12px;
  }
}

@media (width <=550px) {
  .main_input_section {
    margin-top: 16px;
  }
  .title {
    margin-bottom: 8px;
  }
  .main_input {
    padding: 10px 8px;
    height: 35px;
    border-radius: 4px;
  }
}

@media (width <=470px) {
  .main_input {
    padding: 14px 10px;
    height: 40px;
    font-size: 12px;
  }
}
